<template>
  <div>
    <!-- {{selected_package_group}} -->
    <!-- {{selected_package_group['id']}}
    {{selected_package_group['name']}}
    {{selected_package_group['description']}}-->
    <SelectingSearch
      :relationship="$d.getRelationship(class_name, 'IncludeProducts')"
      :_index_params="{
        has_package_group_include_products: [selected_package_group['id']],
        with_relationships: [
          {
            from_class_name: 'Product',
            relationship_name: 'UsingTangible',
          },
        ],
      }"
      :params.sync="params_"
      :enable_map_default="false"
      :enable_maxed_error="false"
      :stateIdentifier="class_name + '_' + selected_package_group['id'] + '_Product'"
      :allow_searchbar="false"
      :allow_properties_filter="false"
      :allow_json_filter="false"
      :allow_relationships_filter="false"
      :allow_select_fields="false"
      :index_all="true"
      :allow_select_all="false"
      @selected="item => onSelected(item)"
      :except_virtual_property_keys="['final_address_pricing']"
      :_exclude_fields="[
        { property: { property_key: 'final_address_pricing' } },
        { property: { property_key: 'address_pricing' } },
        { property: { property_key: 'account_pricing' } },
        { property: { property_key: 'billing_account_pricing' } },
        { property: { property_key: 'min_price' } },
        { property: { property_key: 'default_price' } },
        { property: { property_key: 'max_price' } },
        { property: { property_key: 'minimum_order_quantity' } },
        { property: { property_key: 'warehouses' } },
      ]"
    ></SelectingSearch>
    <!-- {{params}} -->
    <PendingTable
      :relationship="$d.getRelationship(class_name, 'IncludeProducts')"
      :params.sync="params_"
      :enableRemove="!disabled"
      :_exclude_fields="[
        { property: { property_key: 'final_address_pricing' } },
        { property: { property_key: 'address_pricing' } },
        { property: { property_key: 'account_pricing' } },
        { property: { property_key: 'billing_account_pricing' } },
        { property: { property_key: 'price' } },
        { property: { property_key: 'min_price' } },
        { property: { property_key: 'default_price' } },
        { property: { property_key: 'max_price' } },
        { property: { property_key: 'minimum_order_quantity' } },
        { property: { property_key: 'warehouses' } },
      ]"
    ></PendingTable>
    <div>
      <b-alert v-if="selected_quantity < max_quantity" show variant="warning" class="mt-2">
        Selected {{ selected_quantity }} out of {{ max_quantity }}. Add {{ max_quantity - selected_quantity }} more
      </b-alert>
      <b-alert v-else-if="selected_quantity > max_quantity" show variant="danger" class="mt-2">
        Selected {{ selected_quantity }} out of {{ max_quantity }}. Remove {{ selected_quantity - max_quantity }} more</b-alert
      >
      <b-alert v-else-if="selected_quantity == max_quantity" show variant="success" class="mt-2">
        Selected {{ selected_quantity }} out of {{ max_quantity }}.
      </b-alert>
    </div>
    <!-- :enableRemove="false" -->
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  components: {
    SelectingSearch: () => import('@/descriptor/coreui/components/modal/SelectingSearch'),
    PendingTable: () => import('@/descriptor/coreui/components/modal/PendingTable'),
  },
  props: {
    params: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    class_name: {
      type: String,
      default: 'OrderPackageGroup',
    },
  },
  data: () => {
    return {
      params_: null,
    };
  },
  computed: {
    properties() {
      return this.$d.getClass(this.class_name)['properties'];
    },
    relationships() {
      return this.$d.getRelationships(this.class_name, 'to');
    },
    selected_package_group() {
      return this.params[this.$d.getRelationAliasByNames(this.class_name, 'WithPackageGroup')][0];
    },
    selected_package_group_products() {
      return this.params[this.$d.getRelationAliasByNames(this.class_name, 'IncludeProducts')];
    },
    max_quantity() {
      return this.selected_package_group['total_quantity'];
    },
    selected_quantity() {
      return _.sumBy(this.selected_package_group_products, package_group_product => {
        return Number(package_group_product['include_products']['quantity']);
      });
    },
  },
  watch: {
    params(to, from) {
      this.params_ = this.params;
      console.log('new external param');
    },
    params_(to, from) {
      console.log('update external param');
      this.$emit('update:params', this.params_);
    },
  },
  created() {
    this._ = _;

    this.init();

    if (!this.selected_package_group_products) {
      if (!this.params[this.$d.getRelationAliasByNames(this.class_name, 'IncludeProducts')]) {
        this.$set(this.params, this.$d.getRelationAliasByNames(this.class_name, 'IncludeProducts'), []);
      }
    }
  },
  methods: {
    init() {
      this.params_ = this.params;
    },
    onSelected(item) {
      console.log('selected', item);

      this.params[this.$d.getRelationAliasByNames(this.class_name, 'IncludeProducts')].forEach(included_product => {
        console.log(included_product);

        let pivot = included_product['include_products'];

        pivot['quantity'] = this.selected_package_group['total_quantity'];
      });
    },
  },
};
</script>

<style scoped></style>
