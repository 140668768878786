var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SelectingSearch',{attrs:{"relationship":_vm.$d.getRelationship(_vm.class_name, 'IncludeProducts'),"_index_params":{
      has_package_group_include_products: [_vm.selected_package_group['id']],
      with_relationships: [
        {
          from_class_name: 'Product',
          relationship_name: 'UsingTangible',
        },
      ],
    },"params":_vm.params_,"enable_map_default":false,"enable_maxed_error":false,"stateIdentifier":_vm.class_name + '_' + _vm.selected_package_group['id'] + '_Product',"allow_searchbar":false,"allow_properties_filter":false,"allow_json_filter":false,"allow_relationships_filter":false,"allow_select_fields":false,"index_all":true,"allow_select_all":false,"except_virtual_property_keys":['final_address_pricing'],"_exclude_fields":[
      { property: { property_key: 'final_address_pricing' } },
      { property: { property_key: 'address_pricing' } },
      { property: { property_key: 'account_pricing' } },
      { property: { property_key: 'billing_account_pricing' } },
      { property: { property_key: 'min_price' } },
      { property: { property_key: 'default_price' } },
      { property: { property_key: 'max_price' } },
      { property: { property_key: 'minimum_order_quantity' } },
      { property: { property_key: 'warehouses' } },
    ]},on:{"update:params":function($event){_vm.params_=$event},"selected":item => _vm.onSelected(item)}}),_c('PendingTable',{attrs:{"relationship":_vm.$d.getRelationship(_vm.class_name, 'IncludeProducts'),"params":_vm.params_,"enableRemove":!_vm.disabled,"_exclude_fields":[
      { property: { property_key: 'final_address_pricing' } },
      { property: { property_key: 'address_pricing' } },
      { property: { property_key: 'account_pricing' } },
      { property: { property_key: 'billing_account_pricing' } },
      { property: { property_key: 'price' } },
      { property: { property_key: 'min_price' } },
      { property: { property_key: 'default_price' } },
      { property: { property_key: 'max_price' } },
      { property: { property_key: 'minimum_order_quantity' } },
      { property: { property_key: 'warehouses' } },
    ]},on:{"update:params":function($event){_vm.params_=$event}}}),_c('div',[(_vm.selected_quantity < _vm.max_quantity)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":"","variant":"warning"}},[_vm._v("\n      Selected "+_vm._s(_vm.selected_quantity)+" out of "+_vm._s(_vm.max_quantity)+". Add "+_vm._s(_vm.max_quantity - _vm.selected_quantity)+" more\n    ")]):(_vm.selected_quantity > _vm.max_quantity)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":"","variant":"danger"}},[_vm._v("\n      Selected "+_vm._s(_vm.selected_quantity)+" out of "+_vm._s(_vm.max_quantity)+". Remove "+_vm._s(_vm.selected_quantity - _vm.max_quantity)+" more")]):(_vm.selected_quantity == _vm.max_quantity)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":"","variant":"success"}},[_vm._v("\n      Selected "+_vm._s(_vm.selected_quantity)+" out of "+_vm._s(_vm.max_quantity)+".\n    ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }